import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import Hero from "../components/landing/Hero"
import Feature from "../components/landing/Feature"
import Contact from "../components/landing/Contact"
import Footer from "../components/landing/Footer"
import Resources from "../components/landing/Resources"
import Header from "../components/landing/Header"

const IndexPage = () => (
  <div className="">
    <SEO title="Home" />
    <BaseLayout>
      <Header />
      <Hero />
      <Feature />
      <Resources />
      <Contact />
      <Footer />
    </BaseLayout>
  </div>
)

export default IndexPage
