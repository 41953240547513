import React from "react"
import {
  CloudUploadIcon,
  CodeIcon,
  LockClosedIcon,
  SwitchVerticalIcon,
  CloudIcon,
  CubeTransparentIcon,
} from "@heroicons/react/outline"
import { SectionHeader } from "../common/SectionHeader"
import { StaticImage } from "gatsby-plugin-image"

const features = [
  {
    name: "1-Click Setup",
    description:
      "Download the configuration file and upload it to your device. Avoiding all complicated setup steps.",
    icon: CloudUploadIcon,
  },
  {
    name: "E2EE Encryption",
    description:
      "Device level end-to-end encryption prevents third parties from accessing your data.",
    icon: LockClosedIcon,
  },
  {
    name: "OTA Updates",
    description:
      "Update the functionalities of your device at any time without complex hassle.",
    icon: SwitchVerticalIcon,
  },
  {
    name: "DLT Audit Log",
    description:
      "Our system uses a ledger database that provides a transparent, immutable, and cryptographically verifiable transaction log ‎to audit all access to your devices.",
    icon: CubeTransparentIcon,
  },
  {
    name: "Powerful API",
    description:
      "Architected using serverless concepts. This allows us to provide outstanding availability and scalability at all possible times.",
    icon: CodeIcon,
  },
  {
    name: "Cloud based",
    description:
      "Amazon Web Services (AWS) delivers reliable, scalable, and cost-effective computing resources on which our system is built upon.",
    icon: CloudIcon,
  },
]

export default function Feature() {
  return (
    <>
      {/* Feature section with screenshot */}
      <div
        className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32"
        id="features"
      >
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <SectionHeader
            sectionTitle="Serverless"
            headline="Manage everything from anywhere"
            description="An intuitive dashboard accessible from any browser, also available as a mobile app"
          />
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <StaticImage
              loading="eager"
              src="../../images/screenshots/concept4.jpg"
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              alt="App Screenshot"
              placeholder="tracedSVG"
              layout="constrained"
            />
          </div>
        </div>
      </div>

      {/* Feature section with grid */}
      <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="security">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <SectionHeader
            sectionTitle="Bulletproof"
            headline="Everything you need to keep your secret"
            description="Designed to be the most secure system, distributed ledger technology (DLT) offers a way to securely and efficiently create a tamper-proof log of sensitive activity."
          />
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map(feature => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
