import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { SectionHeader } from "../common/SectionHeader"

const blogPosts = [
  {
    id: 1,
    title: "Amazon Quantum Ledger Database (QLDB)",
    href: "https://aws.amazon.com/qldb/",
    date: "2021",
    datetime: "2021-01-01",
    category: { name: "Website", href: "https://aws.amazon.com/qldb/" },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/blockchain.jfif"
        alt="Blockchain"
      />
    ),
    // imageUrl:
    //   "https://images.unsplash.com/photo-1561451213-d5c9f0951fdf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2089&q=80",
    preview:
      "Amazon QLDB is a fully managed ledger database that provides a transparent, immutable, and cryptographically verifiable transaction log ‎owned by a central trusted authority. Amazon QLDB can be used to track each and every application data change and maintains a complete and verifiable history of changes over time.",
    author: {
      name: "Amazon Web Services",
      imageUrl: "",
      href: "https://aws.amazon.com/",
    },
    readingLength: "8 min",
  },
  {
    id: 2,
    title: "AWS Well-Architected Framework - SaaS Lens",
    href: "https://docs.aws.amazon.com/wellarchitected/latest/saas-lens/saas-lens.html",
    date: "Dec 3, 2020",
    datetime: "2020-12-03",
    category: {
      name: "Whitepaper",
      href: "https://docs.aws.amazon.com/wellarchitected/latest/saas-lens/wellarchitected-saas-lens.pdf#saas-lens",
    },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/buildings.jfif"
        alt="Buildings"
      />
    ),
    // imageUrl:
    //   "https://images.unsplash.com/photo-1516617539902-51315fafbaa7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80",
    preview:
      "This paper describes the SaaS Lens for the AWS Well-Architected Framework, which enables customers to review and improve their cloud-based architectures and better understand the business impact of their design decisions.",
    author: {
      name: "Amazon Web Services",
      imageUrl: "",
      href: "https://aws.amazon.com/",
    },
    readingLength: "90 min",
  },
  {
    id: 3,
    title: "What is AWS CDK, and why should your DevOps teams use it?",
    href: "https://www.nclouds.com/blog/what-is-aws-cdk-and-why-should-your-devops-teams-use-it/",
    date: "Apr 2021",
    datetime: "2020-04-01",
    category: {
      name: "Article",
      href: "https://www.nclouds.com/blog/what-is-aws-cdk-and-why-should-your-devops-teams-use-it/",
    },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/cdk.jpg"
        alt="AWS Cloud Development Kit"
      />
    ),
    // imageUrl: "https://www.nclouds.com/blog/wp-content/uploads/2021/03/v2.jpg",
    preview:
      "AWS CDK is an open-source software development framework for defining cloud infrastructure as code (IaC) AWS introduced in July 2019. Because AWS CDK uses CloudFormation as a foundation.",
    author: {
      name: "Daniela Metz",
      imageUrl: "",
      href: "https://www.nclouds.com/blog/author/ronaldo-ruiz/",
    },
    readingLength: "11 min",
  },
]

export default function Resources() {
  return (
    <div id="resources">
      <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
        <div className="relative">
          <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <SectionHeader
              sectionTitle="Technology"
              headline="Resources"
              description="Some material about the technology used behind the scenes."
            />
          </div>
          <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
            {blogPosts.map(post => (
              <div
                key={post.id}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  {post.renderImage ? (
                    post.renderImage()
                  ) : (
                    <img
                      className="h-48 w-full object-cover"
                      // src={post.imageUrl}
                      alt=""
                    />
                  )}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-cyan-600">
                      <a
                        target="_blank"
                        href={post.category.href}
                        className="hover:underline"
                        rel="noopener noreferrer"
                      >
                        {post.category.name}
                      </a>
                    </p>
                    <a
                      target="_blank"
                      href={post.href}
                      className="block mt-2"
                      rel="noopener noreferrer"
                    >
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.preview}
                      </p>
                    </a>
                  </div>
                  <div className="mt-6 flex items-center">
                    {post.author.imageUrl && (
                      <div className="flex-shrink-0">
                        <a
                          target="_blank"
                          href={post.author.href}
                          rel="noopener noreferrer"
                        >
                          <img
                            className="h-10 w-10 rounded-full"
                            src={post.author.imageUrl}
                            alt={post.author.name}
                          />
                        </a>
                      </div>
                    )}

                    <div className="">
                      <p className="text-sm font-medium text-gray-900">
                        <a
                          target="_blank"
                          href={post.author.href}
                          className="hover:underline"
                          rel="noopener noreferrer"
                        >
                          {post.author.name}
                        </a>
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.readingLength} read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
